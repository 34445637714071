import { Injectable, inject } from '@angular/core'
import { MessageService } from 'primeng/api'

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private messageService = inject(MessageService)

  showNotification(
    type: 'success' | 'warn' | 'info' | 'error',
    message: string = '',
    duration: number = 3000
  ) {
    this.messageService.add({
      key: 'toast-notification',
      severity: type,
      summary: type.toLocaleUpperCase(),
      life: duration,
      detail: message,
    })
  }
}
